function getQueryParam(key: string) {
  if (typeof window === 'undefined') {
    return null
  }

  const queryParams = new URLSearchParams(window.location.search)

  return queryParams.get(key)
}

export function isEmbeddedView() {
  if (typeof window === 'undefined') {
    return null
  }

  const embedKey = 'embed'

  return getQueryParam('mode') === embedKey && window.top !== window.self
}

export function isEmbeddedDarkMode() {
  const darkModeKey = 'dark'

  return isEmbeddedView() && getQueryParam('theme') === darkModeKey
}
